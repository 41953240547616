<template>
<div class="circular-image">
  <img alt="Vue logo" src="./assets/deen.jpg">
   </div>
  <HelloWorld msg="PharmaDeen"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circular-image {
  display: inline-block;
  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed */
  overflow: hidden;
  border-radius: 50%; /* This makes the image circular */
  margin-right: 20px; /* Add margin to move the image to the left */
  animation: rotate 5s linear infinite; /* Rotate the image */
}

.circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the entire area */
}
</style>
