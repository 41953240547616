<template>
  <div class="hello">
    <h1 class="wiggle">DeenPharma</h1>
    <p>
      Look no further for a pharmacist?
    </p>
    <h3>Professionalism Personified</h3>
    <div class="image-container">
      <img src="../assets/deen2.jpg" class="responsive-image" alt="Pharmacist" />
    </div>
    <h3>Get in touch</h3>
    <ul>
      <li><a :href="mailtoLink">Email Me</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: 'support@pharmadeen.co.uk',
      subject: 'Enquiry',
      body: 'Hi, I wanted to reach out regarding...'
    };
  },
  computed: {
    mailtoLink() {
      return `mailto:${this.email}?subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes wiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.wiggle {
  display: inline-block;
  animation: wiggle 0.5s ease-in-out infinite;
}

h1 {
  font-size: 8vw;
  margin: 0;
}

h3 {
  margin: 20px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: block;
  margin: 10px 0;
}

a {
  color: #42b983;
}

.image-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.responsive-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  h1 {
    font-size: 10vw;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 12vw;
  }

  h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }

  .image-container {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 14vw;
  }

  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 0.9em;
  }
}
</style>
